import styled from "styled-components";

export const StyledHeader = styled.div`
  position: relative;
  margin-bottom: 3.6rem;
  // margin-bottom: 6.6rem;

  &.welcomeHeader, &.profileHeader {
    .bgImage {
      width: 100dvw;
      // height: auto;
      height: 12rem;
      border-bottom-left-radius: 2rem;
      border-bottom-right-radius: 2rem;
    }

    .defaultBgImg {
      height: 12rem;
    }

    .backArrow {
      position: absolute;
      top: 1rem;
      left: 0.8rem;

      path {
        fill: white;
      }
    }
  }

  &.welcomeHeader {
    .logo {
      position: absolute;
      bottom: -3.4rem;
      // bottom: -5.4rem;
      left: 17%;
      transform: translate(-50%, 0px);
    }

    .thankYouheaderLogo{
      left: 50% !important;
      bottom: -4rem !important;
    }

    .brandLogo {
      width: 8rem;
      height: 8rem;
      // width: 12.5rem;
      // height: 12.5rem;
      // border-radius: 50%;
      // background-color: antiquewhite;

      img {
        width: inherit;
        height: inherit;
        border-radius: 50%;
        object-fit: contain;
        box-shadow: 0 0.5px 1px black;
      }
    }
  }

  &.profileHeader {
    margin-bottom: 6rem;

    .bgImage {
      height: 12rem;
    }

    .defaultIcon {
      position: absolute;
      // bottom: -7.8rem;
      // bottom: -5.5rem;
      // bottom: -3.5rem;
      bottom: -3.2rem;
      left: 5.6rem;
      // left: 7.6rem;
      // left: 50%;
      transform: translate(-50%, 0px);
      width: 8rem;
      height: 8rem;
      border-radius: 50%;
      background-color: #f1f1f1;
      display: flex;
      align-items: end;
      justify-content: center;
    }

    .logo {
      width: 70px;
      // width: 100px;
      // width: 150px;
      height: auto;
      fill: lightgrey;
      margin-bottom: -4px;
      // margin-bottom: -6px;
      // margin-bottom: -10px;

      path {
        stroke: lightgrey;
      }
    }
  }

  &.profileSubSectionsHeader {
    background: #F6F6F6;
    border-radius: 0 0 3rem 3rem;
    padding: 1.4rem 2rem;
    margin: 0;

    .logo {
      width: 38px;
      height: auto;
    }

    .textLogo {
      height: 34px;
      width: auto;
    }
  }
`;
