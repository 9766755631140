import React from "react";

import {
  BackArrowIcon, GratifyLogoIcon, GratifyTextLogo, ProfileTabIcon,
  RightArrow
} from "../../../assets/svg";

import { Link } from "react-router-dom";
import { myRewardHeader, profileHeader, welcomeHeader } from "../../../assets/png";
import { PROFILE } from "../../../routes/RoutesConstant";
import { StyledHeader } from "./style";

export function WelcomeHeader({ bg_image, backgroundHexCode, logo: Logo, onBack = null, isLogoReactElement, thankYouFeedbackPage }) {
  return (
    <StyledHeader className="welcomeHeader">
      <div>
        {bg_image
          ? <img src={bg_image} className="bgImage" alt='background-image' />
          : <img src={welcomeHeader} alt="welcome banner" className="bgImage defaultBgImg" />
        }
      </div>

      {onBack && <span><BackArrowIcon className="backArrow cursor_pointer" onClick={onBack} /></span>}

      {Logo
        ? (
          <div className={`brandLogo logo customClass ${thankYouFeedbackPage ? "thankYouheaderLogo" : ""}`}>
            {isLogoReactElement ? <Logo style={{ width: '8rem', height: '8rem', boxShadow: "0 0.5px 1px black", background: `#${backgroundHexCode}` }} /> : <img src={Logo} style={{ background: thankYouFeedbackPage ? "none" : `#${backgroundHexCode}` }} alt='logo' />}
          </div>
        ) : <span><GratifyLogoIcon style={{ width: '8rem', height: '8rem', }} className="logo" /></span>
      }
    </StyledHeader>
  );
}

export function ProfileTabHeader({ onBack = false, isProfileTab = true }) {
  return (
    <StyledHeader className="profileHeader">
      <div>
        {isProfileTab
          ? <img src={profileHeader} alt="profile" className="bgImage" />
          : <img src={myRewardHeader} alt="profile" className="bgImage" />
        }
      </div>

      {/* {!isProfileTab && <span><BackArrowIcon className="backArrow" onBack={() => navigate(PROFILE)} /></span>} */}
      {onBack && <Link to={PROFILE}><BackArrowIcon className="backArrow cursor_pointer" /></Link>}

      <div className="defaultIcon"><ProfileTabIcon className="logo" /></div>
    </StyledHeader>
  );
}

export function ProfileTabSubSectionsHeader({ onBack = true }) {
  return (
    <StyledHeader className="profileSubSectionsHeader display_flex gap_10">
      {onBack && <Link to={PROFILE} style={{ marginTop: "-0.3rem" }} className="cursor_pointer">
        <RightArrow style={{ marginTop: "1rem", width: "2rem", height: "2rem", transform: "rotate(180deg)" }} />
      </Link>}
      {/* <div onBack={() => navigate(PROFILE)}><BackArrowIcon /></div> */}

      <div className="display_flex gap_6">
        <GratifyLogoIcon className="logo" />
        <GratifyTextLogo className="textLogo" />
      </div>
    </StyledHeader>
  );
}
